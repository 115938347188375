span.underlined {
    text-decoration: underline;
}

.sub {
    margin-left: 2em;
}

table.referencias {
    margin-top: 15px;
}

table.referencias td {
    vertical-align: middle;
    padding: .1rem
}

table.referencias td img {
    height: 40px;
}

table.referencias td:nth-child(2) {
    color: #00AEEF;
}