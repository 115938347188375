@font-face {
    font-family: utec_title;
    src: url('./assets/fonts/utec_title.eot');
    src: url('./assets/fonts/utec_title.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/utec_title.woff2') format('woff2'), url('./assets/fonts/utec_title.woff') format('woff'), url('./assets/fonts/utec_title.ttf') format('truetype'), url('./assets/fonts/utec_title.svg#utec_title') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: heavy;
    src: url('./assets/fonts/utec_heavy.eot');
    src: url('./assets/fonts/utec_heavy.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/utec_heavy.woff2') format('woff2'), url('./assets/fonts/utec_heavy.woff') format('woff'), url('./assets/fonts/utec_heavy.ttf') format('truetype'), url('./assets/fonts/utec_heavy.svg#utec_heavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: Roboto;
}

hr {
    clear: both;
    border: 1px solid #DCDFE3;
}

body {
    margin: 0;
}


/* Scrollbar */

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}


/* Handle */
 ::-webkit-scrollbar-thumb {
    background: #7f7f7f;
    border-radius: 10px;
}


/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* styles.css */
.normalFont {
    font-weight: normal !important; 
  }
  